<template>
  <div class="mini-logo">
    <img src="@/assets/img/executorbanner.svg" alt="" />
  </div>
</template>
<style lang="scss">
  .mini-logo {
    min-width: 70px;
    width: 70px;
    height: 70px;
    border: 1px solid #d9d9d9;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 41px;
      height: 34px;
    }
  }
</style>
